import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import {
  Box,
  Grid,
  Grow,
} from '@material-ui/core';
import styles from './App.module.scss';
import { Passwords } from '../passwords/Passwords';

export const App: React.FC = () => (
  <div className={styles.App}>
    <CssBaseline />
    <Grow in>
      <Container maxWidth="sm">
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Box
              component="header"
              textAlign="center"
            >
              <Typography
                variant="h3"
                component="h1"
              >
                Password Generator
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Passwords />
          </Grid>
        </Grid>
      </Container>
    </Grow>
  </div>
);
