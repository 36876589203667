import React, { Component, ReactNode, RefObject } from 'react';
import { Grid, Button } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { PasswordSettings } from './password-settings/PasswordSettings';
import { PasswordList } from './password-list/PasswordList';

interface PasswordsState {
  symbols: string;
  passwordLength: number;
}

export class Passwords extends Component<{}, PasswordsState> {
  protected passwordsToShow = 10;
  protected listRef: RefObject<PasswordList>|null = React.createRef<PasswordList>();

  constructor(props: {}) {
    super(props);
    this.state = {
      symbols: '',
      passwordLength: 8,
    };
    this.onSymbolsChange = this.onSymbolsChange.bind(this);
    this.onPasswordLengthChange = this.onPasswordLengthChange.bind(this);
    this.onRefreshClick = this.onRefreshClick.bind(this);
  }

  protected onSymbolsChange(symbols: string): void {
    this.setState(prevState => ({ ...prevState, symbols }));
  }
  protected onPasswordLengthChange(passwordLength: number): void {
    this.setState(prevState => ({ ...prevState, passwordLength }));
  }
  protected onRefreshClick(): void {
    if (this.listRef && this.listRef.current) {
      this.listRef.current.refresh();
    }
  }

  render(): ReactNode {
    return (
      <Grid
        container
        direction="column"
        spacing={3}
      >
        <Grid item>
          <PasswordList
            symbols={this.state.symbols}
            passwordLength={this.state.passwordLength}
            ref={this.listRef}
          />
        </Grid>
        <Grid item>
          <PasswordSettings
            symbols={this.state.symbols}
            passwordLength={this.state.passwordLength}
            onSymbolsChange={this.onSymbolsChange}
            onPasswordLengthChange={this.onPasswordLengthChange}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<RefreshIcon />}
            onClick={this.onRefreshClick}
          >
            Refresh
          </Button>
        </Grid>
      </Grid>
    );
  }
}
