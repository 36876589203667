import React, { Component, ReactNode } from 'react';
import {
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@material-ui/core';

const lowerCased = 'abcdefghijklmnopqrstuvwxyz';
const upperCased: string = lowerCased.toUpperCase();
const numbers = '0123456789';
const special = '.,!?:;+-*=~()[]{}`"\'_\\|/@#$%^&';

interface PasswordSettingsProps {
  symbols: string;
  passwordLength: number;
  onSymbolsChange: (symbols: string) => void;
  onPasswordLengthChange: (passwordLength: number) => void;
}

interface PasswordSettingsState {
  lowerCased: boolean;
  upperCased: boolean;
  numbers: boolean;
  special: boolean;
}

const settingLabels: { [P in keyof PasswordSettingsState]: string } = {
  lowerCased: 'Lowercase',
  upperCased: 'Capital',
  numbers: 'Numbers',
  special: 'Special',
};

export class PasswordSettings
  extends Component<PasswordSettingsProps, PasswordSettingsState> {
  constructor(props: PasswordSettingsProps) {
    super(props);
    this.state = {
      lowerCased: true,
      upperCased: false,
      numbers: true,
      special: false,
    };
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount(): void {
    if (this.symbols !== this.props.symbols) {
      this.props.onSymbolsChange(this.symbols);
    }
  }

  get symbols(): string {
    const result: string[] = [];
    if (this.state.lowerCased) {
      result.push(lowerCased);
    }
    if (this.state.upperCased) {
      result.push(upperCased);
    }
    if (this.state.numbers) {
      result.push(numbers);
    }
    if (this.state.special) {
      result.push(special);
    }
    return result.join('');
  }

  get unCheckDisabled(): boolean {
    let checkCount = 0;
    Object.keys(this.state).forEach(key => {
      if (this.state[key as keyof PasswordSettingsState]) {
        checkCount += 1;
      }
    });
    return checkCount <= 1;
  }

  refresh(): void {
    console.log(12345);
  }

  protected handleCheckboxChange(e: React.FormEvent<HTMLInputElement>): void {
    const target = e.currentTarget;
    const value: boolean = target.type === 'checkbox' ? target.checked : !!target.value;
    const name = target.name as keyof PasswordSettingsState;
    if (!name || !(name in this.state)) {
      return;
    }
    const currentValue = !!this.state[name];
    if (currentValue === value) {
      return;
    }
    this.setState(
      prevState => ({ ...prevState, [name]: value }),
      () => {
        if (this.symbols !== this.props.symbols) {
          this.props.onSymbolsChange(this.symbols);
        }
      },
    );
  }

  protected handleInputChange(e: React.FormEvent<HTMLInputElement>): void {
    const maxLength = 256;
    const target = e.currentTarget;
    let value: number = +target.value;
    if (!value || Number.isNaN(value)) {
      return;
    }
    if (value > maxLength) {
      value = maxLength;
    }
    const currentValue = this.props.passwordLength;
    if (currentValue === value) {
      return;
    }
    this.props.onPasswordLengthChange(value);
  }

  render(): ReactNode {
    const settingKeys = Object.keys(settingLabels) as Array<(keyof PasswordSettingsState)>;
    const checkboxes = settingKeys.map(settingKey => (
      <FormControlLabel
        key={settingKey}
        control={(
          <Checkbox
            checked={this.state[settingKey]}
            onChange={this.handleCheckboxChange}
            disabled={this.state[settingKey] && this.unCheckDisabled}
            name={settingKey}
            value={settingKey}
          />
        )}
        label={settingLabels[settingKey]}
      />
    ));
    const checkboxBlock = (
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">Symbols</FormLabel>
        <FormGroup>
          {checkboxes}
        </FormGroup>
      </FormControl>
    );
    const lengthInput = (
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">Password length</FormLabel>
        <FormGroup>
          <TextField
            type="number"
            value={this.props.passwordLength}
            inputProps={{
              onChange: this.handleInputChange,
            }}
          />
        </FormGroup>
      </FormControl>
    );
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {lengthInput}
        </Grid>
        <Grid item xs={12}>
          {checkboxBlock}
        </Grid>
      </Grid>
    );
  }
}
