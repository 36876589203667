import React, { Component, ReactNode } from 'react';
import { IconButton } from '@material-ui/core';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import copy from 'copy-to-clipboard';
import { generatePassword } from './generatePassword';
import { PasswordProps } from '../PasswordProps';

interface PasswordState {
  password: string|null;
}

export class Password extends Component<PasswordProps, PasswordState> {
  constructor(props: PasswordProps) {
    super(props);
    this.state = {
      password: null,
    };
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  static getDerivedStateFromProps(nextProps: PasswordProps): PasswordState {
    return {
      password: generatePassword(nextProps.symbols, nextProps.passwordLength),
    };
  }

  protected copyToClipboard(): void {
    if (this.state.password) {
      copy(this.state.password);
    }
  }

  refresh(): void {
    console.log(123);
    this.setState((state, props) => (
      { password: generatePassword(props.symbols, props.passwordLength) }
    ));
  }

  render(): ReactNode {
    return (
      <div style={{ wordBreak: 'break-all' }}>
        <IconButton
          size="small"
          color="primary"
          onClick={this.copyToClipboard}
        >
          <AssignmentReturnedIcon />
        </IconButton>
        {' '}
        {this.state.password}
      </div>
    );
  }
}
