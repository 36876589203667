import React, { ReactNode, Component, RefObject } from 'react';
import { Grid } from '@material-ui/core';
import { PasswordProps } from './PasswordProps';
import { Password } from './password/Password';

const passwordsToShow = 10;

export class PasswordList extends Component<PasswordProps, {}> {
  protected passwordRefs: RefObject<Password>[] = [];

  refresh(): void {
    this.passwordRefs.forEach((passwordRef: RefObject<Password>) => {
      if (passwordRef && passwordRef.current) {
        passwordRef.current.refresh();
      }
    });
  }

  render(): ReactNode {
    const passwords: ReactNode[] = [];
    this.passwordRefs = [];
    for (let index = 0; index < passwordsToShow; index += 1) {
      const passwordRef = React.createRef<Password>();
      this.passwordRefs.push(passwordRef);
      passwords.push(
        <Grid
          key={index}
          item
        >
          <Password
            passwordLength={this.props.passwordLength}
            symbols={this.props.symbols}
            ref={passwordRef}
          />
        </Grid>,
      );
    }
    return (
      <Grid container direction="column" spacing={1}>
        {passwords}
      </Grid>
    );
  }
}
